import Vue from 'vue'
import App from '@/App.vue'
import router from '@/plugins/router/index.js'
import vuetify from '@/plugins/vuetify'

// TODO: FILTROS
import "animate.css"
import _ from 'lodash';
import '@/plugins/filtros';

// TODO: CONTROLLERS
import '@/controllers/Usuario.controller.js';
import '@/controllers/Carrinho.controller.js';
import '@/controllers/Google.controller';
import '@/controllers/Facebook.controller';
import '@/controllers/Service.controller';
import '@/controllers/Util.controller';
import '@/controllers/Storage.controller';
import '@/controllers/SuperIngresso.controller';
// import '@/controllers/Sac.controller';


// TODO: PLUGINS
import '@/plugins/config';
import '@/plugins/languagen';
import '@/plugins/service';

// TODO: REGRA DE NEGOCIO
import '@/controllers/RegradeNegocio.controller';


Vue.config.productionTip = true

new Vue({
    render: h => h(App),
    vuetify,
    router,
    methods: {
        onCaptchaVerified() {
            if (this.$config.dados.recaptcha == "invisible") {
                window.grecaptcha.execute()
            }
        },
        onCaptchaExpired() {
            window.grecaptcha.reset()
            console.warn('onCaptchaExpired')
            if (this.$config.dados.recaptcha == "invisible") {
                const time = setInterval(() => {
                    if (window.grecaptcha) {
                        clearInterval(time)
                        window.grecaptcha.execute()
                    }
                }, 1000)
            }
        },
    }
}).$mount('#app')