// @author: Aurenilson Morais
// @date: 29/08/2020
// API SERVICE CRIADO PARA TRANSFERIR DADOS ENTRE O APLICATIVO E O APP

import a from "axios";
import Vue from "vue";
import router from "@/plugins/router/index.js";

import { BASE_URL } from "@/plugins/service/Endpoints.js";

const axios = a.create({
    baseURL: BASE_URL,
});

Vue.prototype.$axios = new Vue({
    data() {
        return {
            modal: {
                show: false,
                titulo: "",
                conteudo: "",
                botao: "",
                link: "",
            },
        };
    },

    methods: {
        async get(url = "", param = false) {
            try {
                const response = await axios({
                    method: "get",
                    url: this.setParams(url),
                    param: param,
                    responseType: "json",
                    responseEncoding: "utf8",
                    headers: this.getHeaders(),
                });
                // console.log(param, "param", url);
                if (response != undefined) {
                    if (response.data.error) {
                        this.modalBar("", response.data.error.message);
                        return false;
                    }
                    return response.data.result ?? response.data;
                } else {
                    this.modalBar("", response);
                }
            } catch (err) {
                this.modalBar("", err);
                return false;
            }
        },

        async post(url = "", dados = [], cache = false) {
            try {
                const response = await axios({
                    method: "post",
                    url: this.setParams(url),
                    data: dados,
                    responseEncoding: "utf8",
                    headers: this.getHeaders(),
                });

                if (response != undefined) {
                    if (response.data.error) {
                        if (response.data.error.code == 4) {
                            this.$usuario.showModalLogin = true;
                            this.$usuario.termoCompra = true;
                            return;
                        }
                        if (response.data.error.code == 1) {
                            this.rota("Home");
                            this.destroy(true);
                        }
                        if (response.data.error.code == 13121) {
                            this.rota("Perfil");
                            this.modalBar("", response.data.error.message);
                            return;
                        }
                        if (
                            response.data.error.code == 13225 ||
                            response.data.error.code == 13227 ||
                            response.data.error.code == 13089
                        ) {
                            this.rota("Perfil");
                            this.modalBar(
                                "",
                                typeof response.data.error.message == "object"
                                    ? response.data.error.message
                                    : JSON.parse(response.data.error.message)
                                          .message
                            );
                            return;
                        }
                        if (
                            response.data.error.code == 13124 ||
                            response.data.error.code == 13087 ||
                            response.data.error.code == 13020 ||
                            response.data.error.code == 13005
                        ) {
                            // this.rota('Perfil')
                            this.modalBar("", response.data.error.message);
                            return false;
                        }
                        this.modalBar("", response.data.error.message);
                        return false;
                    }
                    return response.data.result;
                } else {
                    if (response.error && response.error.code == 71033) {
                        this.modalBar("", response.error.message);
                        return;
                    }

                    if (
                        response.data.error.code == 13225 ||
                        response.data.error.code == 13227
                    ) {
                        this.rota("Perfil");
                    }
                    this.modalBar("", response);
                    return false;
                }
            } catch (err) {
                this.modalBar("", err);
            }
        },

        async postData(url = "", dados = [], cache = false) {
            url = this.setParams(url);

            let form_data = new FormData();

            for (var key in dados) {
                form_data.append(key, dados[key]);
            }

            try {
                const response = await axios({
                    method: "post",
                    url: this.setParams(url),
                    data: form_data,
                    responseEncoding: "utf8",
                    headers: this.getHeaders(),
                });

                if (response != undefined) {
                    if (response.data.error) {
                        if (response.data.error.code == 1) {
                            this.rota("Home");
                            this.destroy(true);
                        }
                        if (
                            response.data.error.code == 13225 ||
                            response.data.error.code == 13227
                        ) {
                            this.rota("Perfil");
                        }
                        if (
                            response.data.error.code == 13124 ||
                            response.data.error.code == 13087 ||
                            response.data.error.code == 13020 ||
                            response.data.error.code == 13005
                        ) {
                            // this.rota('Perfil')
                            this.modalBar("", response.data.error.message);
                            return false;
                        }
                        this.modalBar("", response.data.error.message);
                        return false;
                    }

                    return response.data.result;
                } else {
                    if (response.error && response.error.code == 1) {
                        this.rota("Home");
                        this.destroy(true);
                    }

                    if (response.error && response.error.code == 71033) {
                        this.modalBar("", response.error.message);
                        return;
                    }
                    this.modalBar("", response);
                    return false;
                }
            } catch (err) {
                this.modalBar("", err);
            }
        },

        setLocal(nome, dados) {
            // TODO: setLocal
            var today = new Date();
            return window.sessionStorage.setItem(
                "__" + window.btoa(nome),
                window.btoa(
                    unescape(
                        encodeURIComponent(
                            JSON.stringify({
                                session: today,
                                length: window.btoa(dados).length,
                                data: dados,
                            })
                        )
                    )
                )
            );
        },

        getLocal(nome) {
            // TODO: getLocal
            if ("__" + window.btoa(nome) in sessionStorage) {
                return JSON.parse(
                    decodeURIComponent(
                        escape(
                            window.atob(
                                window.sessionStorage.getItem(
                                    "__" + window.btoa(nome)
                                )
                            )
                        )
                    )
                );
            }
            return false;
        },

        remove(nome) {
            if ("__" + window.btoa(nome) in sessionStorage) {
                window.sessionStorage.removeItem("__" + window.btoa(nome));
            }
            return false;
        },

        destroy(session) {
            this.remove("local_carrinho");
            this.remove("carrinho");
            this.remove("confirm_login");
            if (session) {
                this.remove("cupom");
                this.remove("token");
                this.remove("session");
            }

            setTimeout(() => {
                document.location.reload();
            }, 1000);
        },

        snackbar(mensagem, type, time = 5000) {
            // CRIA O ELEMENTO SNACKBAR PARA O TRATAMENTO DE ERROS;
            let snackbar;
            snackbar = document.createElement("div");
            snackbar.setAttribute("id", "snackbar");

            snackbar.style.cssText = `
                position: fixed;
                top: 0px;
                width: calc(100% - 20px);
                padding: 10px;
                margin: 10px;
                border-radius: 5px;
                display: flex;
                z-index: 99999;
                text-align: center
            `;

            if (type == "error") {
                snackbar.style.cssText += `
                    border: solid 1px rgba(204, 0, 0, 1) !important;
                    background: #FF9E9E !important;
                    color: rgba(204, 0, 0, 1) !important;
                `;
            } else if (type == "sussess") {
                snackbar.style.cssText += `
                    border: solid 1px #0cd40c !important;
                    background: #a8ffd7 !important;
                    color: #0cd40c !important;
                `;
            } else {
                snackbar.style.cssText += `
                    border: solid 1px gray !important;
                    background: white !important;
                    color: gray !important;
                `;
            }

            snackbar.innerHTML = mensagem;
            document.body.appendChild(snackbar);

            setTimeout(function () {
                snackbar.remove();
            }, time);
        },

        modalBar(titulo, conteudo) {
            this.modal.show = true;
            this.modal.showclose = true;
            this.modal.titulo = titulo;
            this.modal.conteudo = conteudo;
        },

        serialize(obj) {
            var str = [];
            for (var p in obj)
                if (obj.hasOwnProperty(p)) {
                    str.push(
                        encodeURIComponent(p) + "=" + encodeURIComponent(obj[p])
                    );
                }
            return str.join("&");
        },

        rota(nome) {
            if (nome.length == 0) {
                console.error("Não existe link por favor verificar!");
                return;
            }
            if (nome.indexOf("http") != -1) {
                window.open(nome, "_blank");
                return;
            }
            if (router.history.current.name != nome) {
                router.push({ name: nome });
            }
            window.scrollTo(0, 0);
        },

        getHeaders() {
            let headers = { "Content-Type": "application/json" };

            if (this.getLocal("token")) {
                headers = {
                    Authorization: "Bearer " + this.getLocal("token").data,
                    "Content-Type": "application/json",
                };
            }

            return headers;
        },

        setParams(data) {
            if (this.getLocal("lang")) {
                let l = this.getLocal("lang").data;

                if (data.indexOf("?") != -1) {
                    return data + "&lang=" + l.replace("-", "_") + "&DEBUG=1"; // + '&ambiente=' + ENV_CONF.AMBIENTE
                } else {
                    return data + "?lang=" + l.replace("-", "_") + "&DEBUG=1"; // + '&ambiente=' + ENV_CONF.AMBIENTE
                }
            }
            return data;
        },
    },
});
