export default {
    methods: {

        async getBrowserUUID() {
            var navigator_info = window.navigator;
            var screen_info = window.screen;
            var uid = navigator_info.mimeTypes.length;
            uid += navigator_info.userAgent.replace(/\D+/g, '');
            uid += navigator_info.plugins.length;
            uid += screen_info.height || '';
            uid += screen_info.width || '';
            uid += screen_info.pixelDepth || '';
            return this.uuidTohex(uid)
        },
    
        uuidTohex(uuid) {
            if(uuid){
                let uid = uuid.toString().replace(/\D+/g, "");
                if(uid.length < 40){
                    for (let i = 0; i < 40; i++) {
                        uid += 0;
                    }
                }
                uid = uid.substring(0, 40)
                return uid.toString()
            }
            return false
        },

        mobile() { // TODO: mobile
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];
        
            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        },


        getCheckoutStepUniversal(step, etapa){

            var varCounter = 0;

            let time = setInterval(function(){
                if(varCounter >= 10){
                    clearInterval(time);
                }
                
                if(window.gaData){
                    clearInterval(time);
                    // DEFINE AS PROPRIEDADES DO COMERCIO ELETRONICO AVANÇADO

                    ga('ec:setAction','checkout', { 'step': step, 'option': etapa });
                    ga('send', 'pageview');

                    ga('ec:setAction', 'checkout_option', {
                        'step': step,
                        'option': etapa
                    });
                    
                    ga('send', 'event', 'Checkout', 'Option', {
                        hitCallback: function() {
                            // Advance to next page.
                            console.warn('Checkout step: ' + step.toString() + ', Etapa: ' + etapa)
                        }
                    });

                }

                varCounter++
            }, 1000)
            
        },

        enviarConversaoUniversal(produtos){
            if(!window.gaData || !window.ga){
                console.warn('Falha no envio da conversão!')
                return
            }
            let itens = [];
           
            produtos.forEach(i => {
                itens.push(i)
            })

            // ENVIA OS PRODUTOS

            let total = 0;
            let taxa = 0;

            itens.forEach((item, index) => {
                total += Number(item.valor * (item.qtd || 1))
                taxa += Number(item.taxa)

                let dados = {
                    id: item.idprod,
                    name: item.produto,
                    category: item.tpproduto,
                    brand: 'Eleventickets',
                    variant: 'Ticket',
                    price: (Number(item.valor).toFixed(2)).toString(),
                    quantity: Number(item.qtd || 1),
                    position: index + 1                   
                }

                if(item.cupom){
                    dados.coupon = item.cupom
                }

                ga('ec:addProduct', dados);
                ga('ec:addImpression', dados);

            })

            ga('ec:setAction', 'detail');

            let plataforma = this.mobile() ? 'Mobile' : 'Desktop'

            ga('ec:setAction', 'purchase', {
                'action': 'purchase',
                'affiliation': "Superingresso - " + plataforma,
                'id': new Date().getTime(),
                'revenue': (Number(total).toFixed(2)).toString(),
                'shipping': "0",
                'tax': (Number(taxa).toFixed(2)).toString()
            });

            ga('send', 'pageview');

            ga('send','event','Ecommerce','Purchase','revenue', { nonInteraction: true });
            this.getCheckoutStepUniversal(3, 'Finalizando pagamendo')

        },

        cancelarItemUniversal(id) {
            if(!window.gaData || !window.ga){
                console.warn('Falha na remoção da conversão!')
                return
            }
            ga('ec:setAction', 'refund', {
                'id': id
            });
        },

        async initUniversal(key) {

            let gtm = document.createElement("script");
            gtm.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" + key + "');";
            document.head.prepend(gtm);

            let initializer = document.createElement("noscript");
            initializer.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=' + key + '"height="0" width="0" style="display:none;visibility:hidden"></iframe>"';
            document.body.prepend(initializer);

            var varCounter = 0;
            // const self = this
            let time = setInterval(function(){
                if(varCounter >= 10){
                    clearInterval(time);
                }
                
                if(window.gaData && window.ga){
                    clearInterval(time);
                    // DEFINE AS PROPRIEDADES DO COMERCIO ELETRONICO AVANÇADO

                    ga("create",  Object.keys(window.gaData)[0]);
                    ga('require', 'ec');
                    ga('set', 'currencyCode', 'BRL');
                    ga('set', 'anonymizeIp', true);

                    console.warn('GA Universal implementado')

                    // self.test()

                }

                varCounter++
            }, 1000)

        },

        test(){
            return {
                "idmovimentacao": "c3e4a55d69c4a2c0a6eb4b1db8c1362ea7226f48",
                "dthr_reserva": "2022-12-19 14:47:53.18136",
                "entrega_end": null,
                "entrega_valor": "0",
                "entrega_valor_juros": "0",
                "localizador": "1219K4W5H6",
                "convenio_reserva": null,
                "status": "RE",
                "idformapag_venda": null,
                "dthr_pagamento": null,
                "parcelas_gtw": null,
                "modo_pagamento": null,
                "texto_apoio": null,
                "qtd": 1,
                "valor_cancelado": null,
                "valor_juros": null,
                "total": null,
                "valorTotal": 0,
                "produtos": [
                    {
                        "iditem": "89227282fabe9a6770681e19015eb8037b01a8a0",
                        "cancelar": false,
                        "idprod": "7162cc908f0930fd53abdcc16c34605e3c9d5597",
                        "idapre": "3f97b30ff1a53e508930e8bf933f95a5f5190382",
                        "dt_apre": "2022-12-21 08:00:00",
                        "dscapre": "TESTES IMPLY",
                        "tem_cupom": true,
                        "cupom": null,
                        "cartao": null,
                        "produto": "TESTES",
                        "dthr_exp": "2022-12-20 14:47:53",
                        "count_exp": 86390,
                        "status": "RE",
                        "valor_cupom": 0,
                        "valor": 999,
                        "valor_sem_desconto": 999,
                        "taxa": 0,
                        "cancel": 0,
                        "juros": 0,
                        "total": 999,
                        "tp_cancel": null,
                        "boleto_url": null,
                        "combo": true,
                        "midia_origem": false,
                        "msg_info_carrinho": "",
                        "idapresentacao": "3f97b30ff1a53e508930e8bf933f95a5f5190382",
                        "dscproduto": "TESTES",
                        "idproduto": "7162cc908f0930fd53abdcc16c34605e3c9d5597",
                        "preco": 999
                    },
                    {
                        "iditem": "89227282fabe9a6770681e19015eb8037b01a8a0",
                        "cancelar": false,
                        "idprod": "7162cc908f0930fd53abdcc16c34605e3c9d5597",
                        "idapre": "3f97b30ff1a53e508930e8bf933f95a5f5190382",
                        "dt_apre": "2022-12-21 08:00:00",
                        "dscapre": "TESTES IMPLY",
                        "tem_cupom": true,
                        "cupom": null,
                        "cartao": null,
                        "produto": "TESTES",
                        "dthr_exp": "2022-12-20 14:47:53",
                        "count_exp": 86390,
                        "status": "RE",
                        "valor_cupom": 0,
                        "valor": 999,
                        "valor_sem_desconto": 999,
                        "taxa": 0,
                        "cancel": 0,
                        "juros": 0,
                        "total": 999,
                        "tp_cancel": null,
                        "boleto_url": null,
                        "combo": true,
                        "midia_origem": false,
                        "msg_info_carrinho": "",
                        "idapresentacao": "3f97b30ff1a53e508930e8bf933f95a5f5190382",
                        "dscproduto": "TESTES",
                        "idproduto": "7162cc908f0930fd53abdcc16c34605e3c9d5597",
                        "preco": 999
                    }
                ],
                "cancelar": [
                    {
                        "iditem": "89227282fabe9a6770681e19015eb8037b01a8a0",
                        "cancelar": false,
                        "idprod": "7162cc908f0930fd53abdcc16c34605e3c9d5597",
                        "idapre": [
                            "3f97b30ff1a53e508930e8bf933f95a5f5190382"
                        ],
                        "dt_apre": "2022-12-21 08:00:00",
                        "dscapre": "TESTES IMPLY",
                        "tem_cupom": true,
                        "cupom": null,
                        "cartao": null,
                        "produto": "TESTES",
                        "dthr_exp": "2022-12-20 14:47:53",
                        "count_exp": 86390,
                        "status": "RE",
                        "valor_cupom": 0,
                        "valor": 999,
                        "valor_sem_desconto": 999,
                        "taxa": 0,
                        "cancel": 0,
                        "juros": 0,
                        "total": 999,
                        "tp_cancel": null,
                        "boleto_url": null,
                        "combo": true,
                        "midia_origem": false,
                        "msg_info_carrinho": "",
                        "idapresentacao": "3f97b30ff1a53e508930e8bf933f95a5f5190382",
                        "dscproduto": "TESTES",
                        "idproduto": "7162cc908f0930fd53abdcc16c34605e3c9d5597",
                        "preco": 999
                    }
                ],
                "exp": "2022-12-20 14:47:53",
                "idapresentacao": "3f97b30ff1a53e508930e8bf933f95a5f5190382",
                "dscapre": "TESTES IMPLY",
                "dt_apre": "2022-12-21 08:00:00",
                "res": "2022-12-19 14:47:53.18136"
            }

        }
    },
}