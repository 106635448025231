import Vue from 'vue';
import "@/plugins/config.js";
import "@/plugins/service/index.js";
import '@/controllers/Carrinho.controller.js';
import '@/plugins/languagen/index.js';

import conf from '../../package.json'

Vue.prototype.$regras = new Vue({
    methods: {

        add(item, apre) {

            // if (typeof apre.dt_apre == 'object' && apre.dt_apre.length < 2) {
            //     this.$axios.modalBar('', "Informe <strong>(duas)</strong> datas para a sua visita!")
            //     return false
            // }

            if (!conf.otmizacao.carrinhoLocal) {
                this.$usuario.showModalLogin = true
                return false
            }

            let session = this.$axios.getLocal('session').data;

            // VERIFICA SE O ITEM TEM O LIMITE DE COMPRA

            /** VERIFICA LIMITE MAX POR APRESENTACAO DO PUBLICO GERAL */
            // const limitePublicoGeral = this.$config.regrasDoNegocio.limiteIngressosPorCPFPorApresentacao;
            // if (item.qtd >= limitePublicoGeral && item.modalidade != 3 && session.idpessoa_tipo != this.$config.regrasDoNegocio.idagencia) {
            //     this.$axios.modalBar('', this.$t_('limite_compra_atingido'))
            //     return false
            // }
            
            return true
        },

        pagar() {
            let session = this.$axios.getLocal('session') ? this.$axios.getLocal('session').data : false;
            let carrinho = [];
            let retorno = true;

            if(session) {
                carrinho = this.$axios.getLocal('carrinho').data; 
            } else {
                carrinho = this.$axios.getLocal('local_carrinho').data;
            }

            // const limiteMinAgencia = this.$config.regrasDoNegocio.limiteMinIngressosPorAgenciaPorCPFPorApresentacao;
            // carrinho.forEach(cart => {
            //     /** VERIFICA LIMITE MIN POR APRESENTACAO DA AGÊNCIA */
            //     if (session && cart.qtd < limiteMinAgencia && cart.modalidade != 3 && session.idpessoa_tipo == this.$config.regrasDoNegocio.idagencia) {
            //         this.$axios.modalBar('', this.$t_('limite_minimo_compra_agencia'));
            //         retorno = false;    
                                    
            //         return;
            //     }                 
            // });

            return retorno;
        }
    }
})